import React from 'react';
import styled from 'styled-components';
import SecureLockImage from '../../../images/product-assets/security-and-compliance-highlight-lock.svg';
import GradientLagreScreen from '../../../images/product-assets/security-and-compliance-highlight-gradient-large.svg';
import { useModal } from '../../../context/ModalContext';

const Container = styled.section`
    height: 416px;
    position: relative;
    background: rgba(16, 31, 71, 1);
    overflow: hidden;
    background-blend-mode: multiply;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: url(${GradientLagreScreen}) no-repeat center center/cover;
        mix-blend-mode: hard-light;
    }

    @media only screen and (max-width: 820px) {
        height: 330px;

        &::before {
            height: 100%;
            background-size: cover;
        }
    }

    @media only screen and (max-width: 560px) {
        height: 475px;

        &::before {
            background: radial-gradient(
                ellipse at 50% 45%,
                rgb(20, 63, 173) 26%,
                rgb(16, 31, 71, 0.1) 83%
            );
        }
    }
`;

const Contents = styled.div`
    margin: 0 auto;
    height: 100%;
    max-width: 1280px;
    padding: 0 24px;
    display: flex;
    position: relative;
    > * {
        flex: 1;
    }

    @media only screen and (max-width: 560px) {
        flex-direction: column-reverse;
    }
`;
const LeftContents = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;

    > div {
        display: flex;
        flex-direction: column;
        gap: 44px;

        @media only screen and (max-width: 560px) {
            align-items: center;
            gap: 24px;
        }
    }
`;
const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #fff;
    max-width: 482px;
    margin: auto;

    @media only screen and (max-width: 560px) {
        max-width: 309px;
        gap: 18px;
        align-items: center;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    line-height: 48px;
    width: 404px;
    font-weight: 600;

    @media only screen and (max-width: 1024px) {
        font-size: 32px;
        line-height: 36px;
        width: 300px;
    }

    @media only screen and (max-width: 820px) {
        font-size: 24px;
        line-height: 28px;
        width: 236px;
    }
    @media only screen and (max-width: 560px) {
        text-align: center;
    }
`;
const SubText = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    @media only screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
        width: 400px;
    }
    @media only screen and (max-width: 820px) {
        width: 308px;
        font-size: 14px;
        line-height: 18px;
    }
    @media only screen and (max-width: 560px) {
        text-align: center;
    }
`;
const Button = styled.button`
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 8px 16px;
    color: rgba(16, 31, 71, 1);
    background: #fff;
    line-height: 16.59px;
    font-size: 14px;
    font-weight: 400;
    width: max-content;
    cursor: pointer;

    &:hover {
        scale: 1.1;
        transition: all 0.2s ease-in-out;

        @media only screen and (max-width: 820px) {
            scale: 1;
        }
    }
`;
const RightContents = styled.div`
    position: relative;
`;
const Image = styled.img`
    position: absolute;
    top: 57%;
    left: 57%;
    transform: translate(-57%, -50%);

    @media only screen and (max-width: 1024px) {
        height: 400px;
        width: 400px;
    }

    @media only screen and (max-width: 820px) {
        height: 300px;
        width: 300px;
    }

    @media only screen and (max-width: 560px) {
        position: absolute;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -50%);
        width: 250px;
        height: 250px;
    }
`;
const DataAndSecurityHighlight = () => {
    const { openModal } = useModal();
    return (
        <Container>
            <Contents>
                <LeftContents>
                    <div>
                        <HeadingContainer>
                            <Heading>We protect your data and security.</Heading>
                            <SubText>
                                We prioritize safeguarding your data and ensuring robust security
                                measures are in place to maintain confidentiality and integrity.
                            </SubText>
                        </HeadingContainer>
                        <Button type="button" onClick={() => openModal('contact')}>
                            Contact Us
                        </Button>
                    </div>
                </LeftContents>
                <RightContents>
                    <Image src={SecureLockImage} alt="Data and Security" draggable="false" />
                </RightContents>
            </Contents>
        </Container>
    );
};

export default DataAndSecurityHighlight;
