import React from 'react';
import RootLayout from './../../layouts/RootLayout';
import HeroSection from '../../sections/Resources/SecurityAndCompliance/HeroSection.jsx';
import DataAndSecurityHighlight from '../../sections/Resources/SecurityAndCompliance/DataAndSecurityHighlight.jsx';
import ComplianceMeasures from '../../sections/Resources/SecurityAndCompliance/ComplianceMeasures.jsx';
import SecurityMeasures from '../../sections/Resources/SecurityAndCompliance/SecurityMeasures.jsx';
import HighlightProducts from '../../sections/Resources/SecurityAndCompliance/HighlightProducts.jsx';
import MetaTagsProvider from '../../components/MetaTagsProvider';
import SecurityFrameworkLabel from '../../sections/Resources/SecurityAndCompliance/SecurityFrameworkLabel.jsx';
const SecurityAndCompliance = () => {
    return (
        <RootLayout>
            <MetaTagsProvider
                title="Authenticate: Security & Compliance in Identity Verification"
                description="Discover how Authenticate ensures top-tier security and compliance in identity verification. Learn about our robust measures to protect data and meet industry regulations."
                path="resources/security-and-compliance"
                ogImage="https://cdn.authenticating.com/public/website/product_pages/security-and-compliance-og-img.png"
                keywords="Identity Verification, Security and Compliance, Data Protection, Regulatory Compliance, Identity Security, KYC Compliance, AML Compliance, Fraud Prevention, Compliance Solutions, Information Security, SOC 2, HIPPA Compliance, RBAC"
                ogDescription="Explore Authenticate's commitment to security and compliance in identity verification. Protect your business with our advanced data protection and regulatory adherence solutions."
                twitterTitle="Authenticate: Security & Compliance in Identity Verification"
                twitterDescription="Learn how Authenticate upholds the highest standards of security and compliance in identity verification. Safeguard your data and ensure regulatory adherence."
            />
            <HeroSection />
            <ComplianceMeasures />
            <SecurityFrameworkLabel />
            <SecurityMeasures />
            <HighlightProducts />
            <DataAndSecurityHighlight />
        </RootLayout>
    );
};

export default SecurityAndCompliance;
